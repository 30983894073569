import "./member.css";
import React, {useEffect, useState} from "react";
import {ApiService} from "../../services/http.service";
import {env, resultsUrlPrefix} from "../../constants/global.vars";
import {
    CurrencyFormat,
    dateTimeFormat,
    dateTimeStringFormat,
    mobileView,
    useForceUpdate
} from "../../services/misc.functions";
import {
    agfCondBets, availableBettingSubTypes, availableBettingTypes,
    BettingTypesMap,
    BulletinMapCardID,
    DailyRaces,
    HippodromesMap,
    OfficialResults, racesActionSubject
} from "../../store/bulletin.store";
import {
    betSlipActionSubject,
    tjkBetSlipOptions,
    tjkBetslipStore,
    tjkOrderedBetTypes,
    tjkSingleLegBetTypes,
    tjkSortedBetTypes, updateBetCount
} from "../../services/betslip.service";
import _ from "lodash";
import {modalServiceSubject} from "../../services/modal.service";
import {useNavigate} from "react-router-dom";
import {bulletinProvider} from "../../services/bulletin.service";
import paths from "../../paths";
import {auth} from "../../store/auth.store";
import Modal from "react-modal";
import {isMobile} from "../../App";

const tabs = [
    {value: "bekleyen-biletlerim", label: "BEKLEYEN", content: <Tickets status={null}/>},
    {value: "kazanan-biletlerim", label: "KAZANAN", content: <Tickets status={2}/>},
    {value: "kaybeden-biletlerim", label: "KAYBEDEN", content: <Tickets status={3}/>},
    {value: "kayitli-biletlerim", label: "KAYITLI", content: <Tickets status={6}/>},
    {value: "hazir-kuponlar", label: "HAZIR KUPONLAR", content: <Tickets status={7}/>},
];

const statusMap: any = {
    0: {
        label: "BEKLEMEDE",
        class: "rgb(218, 126, 41)",
        icon: '<svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M13.5938 8C13.5938 9.61616 12.9517 11.1661 11.8089 12.3089C10.6661 13.4517 9.11616 14.0938 7.5 14.0938C5.88384 14.0938 4.33387 13.4517 3.19107 12.3089C2.04827 11.1661 1.40625 9.61616 1.40625 8C1.40625 6.38384 2.04827 4.83387 3.19107 3.69107C4.33387 2.54827 5.88384 1.90625 7.5 1.90625C9.11616 1.90625 10.6661 2.54827 11.8089 3.69107C12.9517 4.83387 13.5938 6.38384 13.5938 8ZM0 8C0 9.98912 0.790176 11.8968 2.1967 13.3033C3.60322 14.7098 5.51088 15.5 7.5 15.5C9.48912 15.5 11.3968 14.7098 12.8033 13.3033C14.2098 11.8968 15 9.98912 15 8C15 6.01088 14.2098 4.10322 12.8033 2.6967C11.3968 1.29018 9.48912 0.5 7.5 0.5C5.51088 0.5 3.60322 1.29018 2.1967 2.6967C0.790176 4.10322 0 6.01088 0 8ZM6.79688 4.01562V8C6.79688 8.23438 6.91406 8.4541 7.11035 8.58594L9.92285 10.4609C10.2451 10.6777 10.6816 10.5898 10.8984 10.2646C11.1152 9.93945 11.0273 9.50586 10.7021 9.28906L8.20312 7.625V4.01562C8.20312 3.62598 7.88965 3.3125 7.5 3.3125C7.11035 3.3125 6.79688 3.62598 6.79688 4.01562Z" fill="#DA7E29"></path></svg>'
    },
    1: {
        label: "DEVAM EDİYOR",
        class: "rgb(218, 126, 41)",
        icon: '<svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M13.5938 8C13.5938 9.61616 12.9517 11.1661 11.8089 12.3089C10.6661 13.4517 9.11616 14.0938 7.5 14.0938C5.88384 14.0938 4.33387 13.4517 3.19107 12.3089C2.04827 11.1661 1.40625 9.61616 1.40625 8C1.40625 6.38384 2.04827 4.83387 3.19107 3.69107C4.33387 2.54827 5.88384 1.90625 7.5 1.90625C9.11616 1.90625 10.6661 2.54827 11.8089 3.69107C12.9517 4.83387 13.5938 6.38384 13.5938 8ZM0 8C0 9.98912 0.790176 11.8968 2.1967 13.3033C3.60322 14.7098 5.51088 15.5 7.5 15.5C9.48912 15.5 11.3968 14.7098 12.8033 13.3033C14.2098 11.8968 15 9.98912 15 8C15 6.01088 14.2098 4.10322 12.8033 2.6967C11.3968 1.29018 9.48912 0.5 7.5 0.5C5.51088 0.5 3.60322 1.29018 2.1967 2.6967C0.790176 4.10322 0 6.01088 0 8ZM6.79688 4.01562V8C6.79688 8.23438 6.91406 8.4541 7.11035 8.58594L9.92285 10.4609C10.2451 10.6777 10.6816 10.5898 10.8984 10.2646C11.1152 9.93945 11.0273 9.50586 10.7021 9.28906L8.20312 7.625V4.01562C8.20312 3.62598 7.88965 3.3125 7.5 3.3125C7.11035 3.3125 6.79688 3.62598 6.79688 4.01562Z" fill="#DA7E29"></path></svg>'
    },
    2: {
        label: "KAZANDI",
        class: "rgb(0, 128, 0)",
        icon: '<svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7.5 1.90625C9.11616 1.90625 10.6661 2.54827 11.8089 3.69107C12.9517 4.83387 13.5938 6.38384 13.5938 8C13.5938 9.61616 12.9517 11.1661 11.8089 12.3089C10.6661 13.4517 9.11616 14.0938 7.5 14.0938C5.88384 14.0938 4.33387 13.4517 3.19107 12.3089C2.04827 11.1661 1.40625 9.61616 1.40625 8C1.40625 6.38384 2.04827 4.83387 3.19107 3.69107C4.33387 2.54827 5.88384 1.90625 7.5 1.90625ZM7.5 15.5C9.48912 15.5 11.3968 14.7098 12.8033 13.3033C14.2098 11.8968 15 9.98912 15 8C15 6.01088 14.2098 4.10322 12.8033 2.6967C11.3968 1.29018 9.48912 0.5 7.5 0.5C5.51088 0.5 3.60322 1.29018 2.1967 2.6967C0.790176 4.10322 0 6.01088 0 8C0 9.98912 0.790176 11.8968 2.1967 13.3033C3.60322 14.7098 5.51088 15.5 7.5 15.5ZM10.8105 6.62305C11.0859 6.34766 11.0859 5.90234 10.8105 5.62988C10.5352 5.35742 10.0898 5.35449 9.81738 5.62988L6.56543 8.88184L5.18848 7.50488C4.91309 7.22949 4.46777 7.22949 4.19531 7.50488C3.92285 7.78027 3.91992 8.22559 4.19531 8.49805L6.07031 10.373C6.3457 10.6484 6.79102 10.6484 7.06348 10.373L10.8105 6.62305Z" fill="#008000"></path></svg>'
    },
    3: {
        label: "KAYIP",
        class: "rgb(236, 73, 80)",
        icon: '<svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7.5 1.90625C9.11616 1.90625 10.6661 2.54827 11.8089 3.69107C12.9517 4.83387 13.5938 6.38384 13.5938 8C13.5938 9.61616 12.9517 11.1661 11.8089 12.3089C10.6661 13.4517 9.11616 14.0938 7.5 14.0938C5.88384 14.0938 4.33387 13.4517 3.19107 12.3089C2.04827 11.1661 1.40625 9.61616 1.40625 8C1.40625 6.38384 2.04827 4.83387 3.19107 3.69107C4.33387 2.54827 5.88384 1.90625 7.5 1.90625ZM7.5 15.5C9.48912 15.5 11.3968 14.7098 12.8033 13.3033C14.2098 11.8968 15 9.98912 15 8C15 6.01088 14.2098 4.10322 12.8033 2.6967C11.3968 1.29018 9.48912 0.5 7.5 0.5C5.51088 0.5 3.60322 1.29018 2.1967 2.6967C0.790176 4.10322 0 6.01088 0 8C0 9.98912 0.790176 11.8968 2.1967 13.3033C3.60322 14.7098 5.51088 15.5 7.5 15.5ZM5.12695 5.62695C4.85156 5.90234 4.85156 6.34766 5.12695 6.62012L6.50391 7.99707L5.12695 9.37402C4.85156 9.64941 4.85156 10.0947 5.12695 10.3672C5.40234 10.6396 5.84766 10.6426 6.12012 10.3672L7.49707 8.99023L8.87402 10.3672C9.14941 10.6426 9.59473 10.6426 9.86719 10.3672C10.1396 10.0918 10.1426 9.64648 9.86719 9.37402L8.49023 7.99707L9.86719 6.62012C10.1426 6.34473 10.1426 5.89941 9.86719 5.62695C9.5918 5.35449 9.14648 5.35156 8.87402 5.62695L7.49707 7.00391L6.12012 5.62695C5.84473 5.35156 5.39941 5.35156 5.12695 5.62695Z" fill="#EC4950"></path></svg>'
    },
    4: {
        label: "İptal",
        class: "rgb(236, 73, 80)",
        icon: '<svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7.5 1.90625C9.11616 1.90625 10.6661 2.54827 11.8089 3.69107C12.9517 4.83387 13.5938 6.38384 13.5938 8C13.5938 9.61616 12.9517 11.1661 11.8089 12.3089C10.6661 13.4517 9.11616 14.0938 7.5 14.0938C5.88384 14.0938 4.33387 13.4517 3.19107 12.3089C2.04827 11.1661 1.40625 9.61616 1.40625 8C1.40625 6.38384 2.04827 4.83387 3.19107 3.69107C4.33387 2.54827 5.88384 1.90625 7.5 1.90625ZM7.5 15.5C9.48912 15.5 11.3968 14.7098 12.8033 13.3033C14.2098 11.8968 15 9.98912 15 8C15 6.01088 14.2098 4.10322 12.8033 2.6967C11.3968 1.29018 9.48912 0.5 7.5 0.5C5.51088 0.5 3.60322 1.29018 2.1967 2.6967C0.790176 4.10322 0 6.01088 0 8C0 9.98912 0.790176 11.8968 2.1967 13.3033C3.60322 14.7098 5.51088 15.5 7.5 15.5ZM5.12695 5.62695C4.85156 5.90234 4.85156 6.34766 5.12695 6.62012L6.50391 7.99707L5.12695 9.37402C4.85156 9.64941 4.85156 10.0947 5.12695 10.3672C5.40234 10.6396 5.84766 10.6426 6.12012 10.3672L7.49707 8.99023L8.87402 10.3672C9.14941 10.6426 9.59473 10.6426 9.86719 10.3672C10.1396 10.0918 10.1426 9.64648 9.86719 9.37402L8.49023 7.99707L9.86719 6.62012C10.1426 6.34473 10.1426 5.89941 9.86719 5.62695C9.5918 5.35449 9.14648 5.35156 8.87402 5.62695L7.49707 7.00391L6.12012 5.62695C5.84473 5.35156 5.39941 5.35156 5.12695 5.62695Z" fill="#EC4950"></path></svg>'
    },
    5: {label: "Oynanamadı / Hatalı", class: "text-bg-danger"},
    6: {label: "KAYITLI", class: "text-bg-secondary"},
    7: {label: "Hazır Kupon", class: "text-bg-secondary"},
}

export function MemberTickets() {
    const [selectedTab, setSelectedTab] = useState(tabs[0]);
    const forceUpdate = useForceUpdate();
    const handleSelect = (e: any) => {
        if (e.target.id) {
            setSelectedTab(tabs[e.target.id]);
        }
    };

    useEffect(() => {
        if (window.location.pathname.includes('kayitli')) {
            setSelectedTab(tabs[3]);
            forceUpdate();
        }
    }, [window.location.pathname]);

    return (
        <div>
            <div className="page-title-wrapper">
                <div className="container">
                    <div className="">
                        <p className="text-xsmall member-title pt-4 pb-3">Kuponlarım</p>
                        <div>
                            <div className="tab-items-wrapper">
                                {tabs.map(({value, label}: { value: string; label: string }, index) => {
                                    if (value === 'hazir-kuponlar') {
                                        if (auth.member.is_editor === true) {
                                            return (
                                                <div id={`${index}`} key={`tabs-index-${index}`} onClick={handleSelect}
                                                     className={`tab-item ${selectedTab.value === value ? "active" : ""}`}>
                                                    <p id={`${index}`} onClick={handleSelect}
                                                       className={`text-small text-bolder ${selectedTab.value === value ? "text-primary" : "text-white"}`}
                                                    >{label.toUpperCase()}</p>
                                                    {selectedTab.value === value &&
                                                        <div className="tab-active-indicator"></div>}
                                                </div>
                                            );
                                        }
                                    } else {
                                        return (
                                            <div id={`${index}`} key={`tabs-index-${index}`} onClick={handleSelect}
                                                 className={`tab-item ${selectedTab.value === value ? "active" : ""}`}>
                                                <p id={`${index}`} onClick={handleSelect}
                                                   className={`text-small text-bolder ${selectedTab.value === value ? "text-primary" : "text-white"}`}
                                                >{label.toUpperCase()}</p>

                                                {selectedTab.value === value &&
                                                    <div className="tab-active-indicator"></div>}
                                            </div>
                                        );
                                    }

                                })}


                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="">{selectedTab.content}</div>

        </div>
    )
}


let RaceMapping: any = {};
let PlayableMapping: any = {};
let ScoreMapping: any = {};
let horseSeparator = ','
let runSeparator = '/'
let pageShown = 1;
let pageMaxSize = 1;


function Tickets(prop: any) {

    const status = prop.status;
    const [listTickets, setListTickets] = useState<any>(null);
    const [listDetails, setListDetails] = useState<any>([]);
    const [detailTicket, setDetailTicket] = useState<any>(null);
    const [selectedLeg, setSelectedLeg] = useState<any>(0);
    const forceUpdate = useForceUpdate();
    const navigate = useNavigate();


    const loadData = (page: any) => {
        pageShown = page;
        let api = new ApiService();
        api.start('post', env.api + '/games/api/tjk/coupons', {
            status: status,
            page: pageShown
        }, true).then((res: any) => {
            if (res.status) {

                pageMaxSize = res.data.total;

                if (!listTickets || pageShown === 1) {
                    setListTickets(res.data.data)
                } else {
                    setListTickets([...listTickets.concat(res.data.data)])
                }

            } else {
                setListTickets(null)
            }
        }).catch(() => {
            setListTickets(null)
        })
    }

    useEffect(() => {
        let racesActionSubject_subscription = racesActionSubject.subscribe((res: any) => {

            if (res && res.action === 'update') {
                forceUpdate();

            }
        })
        return () => {
            racesActionSubject_subscription.unsubscribe();
        }
    });


    useEffect(() => {
        // status değişince ekranı önce temizliyoruz
        setListTickets(null);
        loadData(1);

    }, [prop, prop.status]);

    if (listTickets === null) {
        return (
            <>
                <p className="text-center p-5">Sayfa yükleniyor lütfen bekleyiniz...</p>
            </>
        );
    }


    const getOfficeResult = async (ticket: any) => {
        let date: any = ticket.card.split('-')[0].substring(4, 8) + '-' + ticket.card.split('-')[0].substring(2, 4) + '-' + ticket.card.split('-')[0].substring(0, 2);
        if (!OfficialResults[date]) {
            let apiUrl = resultsUrlPrefix + `/${date.split('-')[2]}${date.split('-')[1]}${date.split('-')[0]}/`;
            let api = new ApiService();
            return await api.start('get', apiUrl, null, false).then(
                (result: any) => {
                    console.log(result)
                    if (result.status) {
                        OfficialResults[date] = result.data;
                    }
                }
            ).finally(() => {
                return null;
            })
        }
        return null;
    }


    const getStableMate = (horses: any, horse: any) => {
        if (horse.stablemate && Number(horse.stablemate) > 0) {
            return horses.filter((e: any) => Number(e.stablemate) === Number(horse.stablemate))
        }
        return [];
    }

    const addToRaceMap = async (ticket: any) => {

        console.log(ticket)

        return await getOfficeResult(ticket).then(() => {
            let date: any = ticket.card.split('-')[0].substring(4, 8) + '-' + ticket.card.split('-')[0].substring(2, 4) + '-' + ticket.card.split('-')[0].substring(0, 2);
            console.log("OfficialResults", OfficialResults[date])

            let hippodrome = DailyRaces.find((h: any) => {
                console.log(h.card_id);
                console.log(ticket.card_id);
                return Number(h.card_id) === Number(ticket.card_id);
            })
            console.log(hippodrome);
            if (hippodrome && hippodrome.runs) {

                for (let rRun of hippodrome.runs) {
                    rRun.is_finished = false;
                    console.log(rRun);
                    PlayableMapping[`${ticket.card_id}-${rRun.no}`] = rRun;
                    RaceMapping[`${ticket.card_id}-${rRun.no}`] = rRun;
                }
            }

            hippodrome = OfficialResults[date].find((el: any) => (el.id.split('-')[1]) === ticket.card.split('-')[1]);
            if (hippodrome && hippodrome.runs) {
                for (let rRun of hippodrome.runs) {
                    rRun.is_finished = true;
                    RaceMapping[`${ticket.card_id}-${rRun.no}`] = rRun;
                }
            }

            if (tjkOrderedBetTypes.includes(Number(ticket.bet_type)) || [52, 55, 62].includes(Number(ticket.bet_type))) {


                let winners = [];

                let run = RaceMapping[`${ticket.card_id}-${ticket.race_id}`]
                if (run !== undefined) {
                    let horses = _.orderBy(run.horses.filter((el: any) => el.result !== '' && el.result !== null), [(e) => Number(e.result)])
                    for (let i in Array.from(Array(BettingTypesMap[Number(ticket.bet_type)].column).keys())) {

                        console.log(i)

                        if (run && run.is_finished !== false) {
                            winners.push(horses[i].no)
                            ScoreMapping[`${ticket.card_id}-${ticket.bet_type}-${ticket.race_id}`] = winners.join(runSeparator)
                        } else {
                            ScoreMapping[`${ticket.card_id}-${ticket.bet_type}-${ticket.race_id}`] = '-'
                        }
                    }
                }
            }

            if (tjkSortedBetTypes.includes(Number(ticket.bet_type)) || tjkSingleLegBetTypes.includes(Number(ticket.bet_type))) {

                console.log("tjkSingleLegBetTypes", BettingTypesMap[Number(ticket.bet_type)].horseCount)

                let run = RaceMapping[`${ticket.card_id}-${ticket.race_id}`]
                let horses = _.orderBy(run.horses.filter((el: any) => el.result !== ''), [(e) => Number(e.result)])
                let winners = [];
                for (let i in Array.from(Array(BettingTypesMap[Number(ticket.bet_type)].horseCount).keys())) {
                    winners.push(horses[i].no)
                }
                ScoreMapping[`${ticket.card_id}-${ticket.bet_type}-${ticket.race_id}`] = winners.join(runSeparator)
            }


            if (![52, 55, 62].includes(Number(ticket.bet_type)) && !tjkOrderedBetTypes.includes(Number(ticket.bet_type)) && !tjkSortedBetTypes.includes(Number(ticket.bet_type)) && !tjkSingleLegBetTypes.includes(Number(ticket.bet_type))) {
                for (let i in Array.from(Array(ticket.legs.length).keys())) {

                    console.log(`${ticket.card_id}-${ticket.race_id + Number(i)}`)

                    let run = RaceMapping[`${ticket.card_id}-${ticket.race_id + Number(i)}`]
                    if (run !== undefined) {
                        let horses = _.orderBy(run.horses, (e) => Number(e.result === '' || e.result === null ? 99999 : e.result))

                        if (run && run.is_finished) {

                            let winners = [];
                            winners.push(horses[0].no)

                            let stablemate = getStableMate(horses, horses[0])
                            if (stablemate.length > 0) {
                                winners.push(...stablemate.map((e: any) => e.no))
                            }

                            if (String(horses[0].result) === "1" && String(horses[1].result) === "1") {
                                winners.push(horses[1].no)
                                let stablemate = getStableMate(horses, horses[1])
                                if (stablemate.length > 0) {
                                    winners.push(...stablemate.map((e: any) => e.no))
                                }
                            }


                            if (agfCondBets.includes(ticket.bet_type)) {

                                let AGF: any = 0
                                //fixme AGF konusu net değil bi tekrar yazmak lazım
                                if (horses[0].AGF_rank1 && Number(horses[0].AGF_rank1) === 1) {
                                    AGF = 1
                                }
                                if (horses[0].EFG) {
                                    AGF = 1
                                }

                                if (AGF === 1) {

                                    let not_run_horses = horses.filter((e: any) => e.degree === null && (e.result === null || e.result === ''))
                                    for (let noRun of not_run_horses) {
                                        winners.push(noRun.no)
                                        let stablemate = getStableMate(horses, noRun)
                                        if (stablemate.length > 0) {
                                            winners.push(...stablemate.map((e: any) => e.no))
                                        }
                                    }

                                }

                                if (Number(ticket.bet_type) === 19) {
                                    winners.push(horses[1].no)
                                }


                            } else if ([47, 48].includes(ticket.bet_type)) {
                                winners.push(horses[1].no)
                                if (horses[2]) {
                                    winners.push(horses[2].no)
                                }
                            } else if ([2, 4].includes(ticket.bet_type)) {
                                winners.push(horses[1].no)
                            }


                            ScoreMapping[`${ticket.card_id}-${ticket.bet_type}-${ticket.race_id + Number(i)}`] = [...Array.from(new Set(winners))].join(horseSeparator)

                        } else {
                            ScoreMapping[`${ticket.card_id}-${ticket.bet_type}-${ticket.race_id + Number(i)}`] = '-'
                        }
                    }


                }
            }
        });
    }

    const cancelTjkCoupon = (c: any) => {
        modalServiceSubject.next({
            title: 'Kupon İptal',
            case: "info",
            content: `Kuponunuz iptal edilecek, emin misiniz?`,
            confirm: {
                sure: {
                    label: 'Evet',
                    action: () => {

                        let api = new ApiService();
                        api.start('post', env.api + '/games/api/tjk/cancel', {
                            coupon_id: c.id
                        }, true)
                            .then((res: any) => {
                                if (res.status) {

                                    let i = listTickets.indexOf(c);
                                    listTickets.splice(i, 1)
                                    setListTickets([...listTickets]);

                                } else {

                                    let modalTitle = "İşlem başarısız!";
                                    if (res && res.data && res.data.error && res.data.error.error_description) {
                                        modalTitle = res.message;
                                        res.message += `${res.data.error.error_description}`
                                    }

                                    modalServiceSubject.next({
                                        title: modalTitle,
                                        case: 'danger',
                                        content: res.message,
                                        timeout: 10000,
                                        confirm: {
                                            cancel: {
                                                label: 'Tamam'
                                            }
                                        }
                                    })
                                }
                            })
                            .catch((err: any) => {
                                console.log(err)
                                modalServiceSubject.next({
                                    title: 'Hata',
                                    content: err,
                                    timeout: 3000,
                                    confirm: {
                                        cancel: {
                                            label: 'Tamam'
                                        }
                                    }
                                })
                            })

                    },
                },
                cancel: {
                    label: 'Hayır',
                }
            }
        })
    }

    const deleteTjkCoupon = (c: any) => {
        modalServiceSubject.next({
            title: 'Emin misiniz?',
            case: "info",
            content: `${c.approve_code || c.id} numaralı kuponunuzu silmek istediğinizden emin misiniz?`,
            confirm: {
                sure: {

                    label: 'Sil',
                    action: () => {

                        let api = new ApiService();
                        api.start('post', env.api + '/games/api/tjk/delete-saved', {
                            coupon_id: c.id
                        }, true)
                            .then((res: any) => {
                                if (res.status) {

                                    let i = listTickets.indexOf(c);
                                    listTickets.splice(i, 1)
                                    setListTickets([...listTickets]);

                                } else {

                                    let modalTitle = "İşlem başarısız!";
                                    if (res && res.data && res.data.error && res.data.error.error_description) {
                                        modalTitle = res.message;
                                        res.message += `${res.data.error.error_description}`
                                    }

                                    modalServiceSubject.next({
                                        title: modalTitle,
                                        case: "danger",
                                        content: res.message,
                                        timeout: 10000,
                                        confirm: {
                                            cancel: {
                                                label: 'Tamam'
                                            }
                                        }
                                    })
                                }
                            })
                            .catch((err: any) => {
                                console.log(err)
                                modalServiceSubject.next({
                                    title: 'Hata',
                                    content: err,
                                    timeout: 3000,
                                    confirm: {
                                        cancel: {
                                            label: 'Tamam'
                                        }
                                    }
                                })
                            })

                    },
                },
                cancel: {
                    label: 'Vazgeç',
                }
            }
        })
    }


    const playTicket = (ticket: any) => {


        let betType = BulletinMapCardID[Number(ticket.card_id)].wager_details.find((w: any) => Number(w.type) === Number(ticket.bet_type))
        console.log("betType", betType)
        if (betType && betType.runs.includes(ticket.race_id)) {

            let slipItem = tjkBetslipStore.filter((el: any) => {
                if (
                    el.hippodrome === Number(BulletinMapCardID[Number(ticket.card_id)].id) &&
                    el.bettingType === Number(ticket.bet_type) &&
                    el.bettingSubType === Number(ticket.race_id)
                ) {
                    return el;
                }
            })
            for (let item of slipItem) {
                tjkBetslipStore.splice(tjkBetslipStore.indexOf(item), 1);
            }
            ticket.legs.map((run: any, legIndex: number) => {
                run.map((horse: any, horseIndex: number) => {

                    let isExist: any = tjkBetslipStore.find((el: any) => el.hippodrome === Number(BulletinMapCardID[Number(ticket.card_id)].id)
                        && el.bettingType === Number(ticket.bet_type)
                        && el.bettingSubType === ticket.race_id
                        && el.column === legIndex + 1
                        && el.run === Number(ticket.race_id + legIndex)
                        && el.horse === String(horse));
                    if (!isExist) {
                        let runNo = ticket.race_id;
                        if (BettingTypesMap[Number(ticket.bet_type)].columnLabel !== 'At') {
                            runNo = runNo + legIndex;
                        }

                        if (BulletinMapCardID[Number(ticket.card_id)].runsMap[Number(runNo)].horseMap[horse].run_status) {
                            tjkBetslipStore.push({
                                hippodrome: Number(BulletinMapCardID[Number(ticket.card_id)].id),
                                bettingType: Number(ticket.bet_type),
                                bettingSubType: ticket.race_id,
                                column: legIndex + 1,
                                run: Number(runNo),
                                horse: String(horse)
                            });
                        }
                    }

                })
            })
            console.log(tjkBetslipStore);
            tjkBetSlipOptions.hippodrome = BulletinMapCardID[Number(ticket.card_id)];
            bulletinProvider.getAvailableBettingTypes(BulletinMapCardID[Number(ticket.card_id)]);
            bulletinProvider.selectBettingType(BulletinMapCardID[Number(ticket.card_id)], Number(ticket.bet_type));
            bulletinProvider.selectBettingSubType(availableBettingSubTypes.find((el: any) => Number(el.id) === Number(ticket.race_id)));
            tjkBetSlipOptions.column = 1;
            tjkBetSlipOptions.betSlipMultiply = ticket.multiply;
            updateBetCount.status = true;
            betSlipActionSubject.next({action: "clear", data: null})
            navigate(`${paths.betting.url}/${BulletinMapCardID[Number(ticket.card_id)].keyword.toLowerCase()}${BulletinMapCardID[Number(ticket.card_id)]._id}`);
        } else {
            modalServiceSubject.next({
                title: 'Kapanmış Bahis',
                innerHtml: true,
                content: '<div class="text-center"><p>Bu kupon artık oynanabilir değil. Farklı bir kupon yapabilir ya da yorumcularımızın diğer kuponlarını oynayabilirsiniz.</p></div>',
                confirm: {
                    cancel: {
                        label: "Tamam",
                    },
                },
            });
        }
    }


    const showDetail = (ticket: any) => {

        let i: any = listDetails.findIndex((e: any) => e === ticket.id)

        if (i === -1) {
            addToRaceMap(ticket).then(() => {
                listDetails.push(ticket.id)
                setListDetails(listDetails);
                forceUpdate();
            })
        } else {
            listDetails.splice(i, 1)
            setListDetails(listDetails);
            forceUpdate();
        }
        setDetailTicket(ticket);

    }


    return (
        <>
            {/*<div className="container ">
                <div className="ticket-filter-wrapper">
                    <div className="ticket-date-filter filter-disabled">
                        <div className="date-filter-button">
                            <div className="d-flex">
                                <svg width="14" height="16" viewBox="0 0 14 16" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M13 6.66665H1M9.66667 1.33331V3.99998M4.33333 1.33331V3.99998M4.2 14.6666H9.8C10.9201 14.6666 11.4802 14.6666 11.908 14.4487C12.2843 14.2569 12.5903 13.951 12.782 13.5746C13 13.1468 13 12.5868 13 11.4666V5.86665C13 4.74654 13 4.18649 12.782 3.75867C12.5903 3.38234 12.2843 3.07638 11.908 2.88463C11.4802 2.66665 10.9201 2.66665 9.8 2.66665H4.2C3.0799 2.66665 2.51984 2.66665 2.09202 2.88463C1.71569 3.07638 1.40973 3.38234 1.21799 3.75867C1 4.18649 1 4.74654 1 5.86665V11.4666C1 12.5868 1 13.1468 1.21799 13.5746C1.40973 13.951 1.71569 14.2569 2.09202 14.4487C2.51984 14.6666 3.0799 14.6666 4.2 14.6666Z"
                                        stroke="#004742" strokeWidth="2" stroke-linecap="round"
                                        stroke-linejoin="round"></path>
                                </svg>
                                <div className="d-flex justify-content-ce align-items-center">
                                    <p className="text-small text-primary text-bolder">Tarih Seç</p>
                                </div>
                            </div>
                            <svg xmlns="http://www.w3.org/2000/svg" width="6" height="12" viewBox="0 0 6 12"
                                 fill="none">
                                <path d="M1 11L5 6L1 1" stroke="#004742" strokeWidth="1.5" stroke-linecap="round"
                                      stroke-linejoin="round"></path>
                            </svg>
                        </div>
                    </div>
                    <div className="filter-disabled">
                        <button className="button  tertiary outlined filter-button ">
                            <p className="text-small long-text none   lh-normal">Hipodrom Seç</p>
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path d="M4 6L8 10L12 6" stroke="#004742" strokeWidth="1.5" stroke-linecap="round"
                                      stroke-linejoin="round"></path>
                            </svg>
                        </button>
                    </div>
                    <div className="filter-disabled">
                        <button className="button  tertiary outlined filter-button ">
                            <p className="text-small long-text">Bahis Türü</p>
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path d="M4 6L8 10L12 6" stroke="#004742" strokeWidth="1.5" stroke-linecap="round"
                                      stroke-linejoin="round"></path>
                            </svg>
                        </button>
                    </div>
                </div>
            </div>*/}
            <div className="container ">
                {isMobile ? <div className={"mt-2"}>
                    {listTickets &&
                        listTickets.map((ticket: any, keyTicket: number) => {
                            return (
                                <React.Fragment key={`tickets-${keyTicket}`}>
                                    <div className="mobile-ticket-wrapper">
                                        <div className="pt-3 mobile-ticket-detail-row">
                                            <p className="text-xsmall text-bold">{BettingTypesMap[Number(ticket.bet_type)]?.name}</p>
                                            <div className="d-flex align-items-center">
                                                <p className="text-xsmall text-bold me-1"
                                                   style={{color: statusMap[ticket.status].class}}>{statusMap[ticket.status].label}</p>


                                                <span
                                                    dangerouslySetInnerHTML={{__html: statusMap[ticket.status].icon}}></span>


                                            </div>
                                        </div>
                                        <div className="d-flex pb-2"
                                             style={{
                                                 justifyContent: "space-between",
                                                 borderBottom: "1px solid rgb(146, 145, 145)"
                                             }}>
                                            <p className="text-xsmall">Kupon
                                                no:{ticket.status !== 6 && ticket.approve_code ? ticket.approve_code : (ticket.status === 6 ? ticket.id : "Oynanıyor")}
                                                <svg width="12"
                                                     height="12"
                                                     viewBox="0 0 12 12"
                                                     fill="none"
                                                     xmlns="http://www.w3.org/2000/svg">
                                                    <rect x="4.23779" y="2.08345" width="5.67949" height="5.68011"
                                                          rx="1.25"
                                                          stroke="#353535" strokeWidth="1.5"></rect>
                                                    <path
                                                        d="M7.84632 9.23151V9.23151C7.84632 10.0246 7.20339 10.6675 6.41029 10.6675H3.3335C2.22893 10.6675 1.3335 9.7721 1.3335 8.66753V5.59004C1.3335 4.79702 1.97637 4.15414 2.76939 4.15414V4.15414"
                                                        stroke="#353535" strokeWidth="1.5"></path>
                                                </svg>
                                            </p>
                                            <p className="text-xsmall "> {dateTimeFormat(ticket.approve_date ?? ticket.create_date).replace(' ', ' , ')}</p>
                                        </div>
                                        <div className="mobile-ticket-detail-row">
                                            <p className="text-xsmall">Hipodrom</p>
                                            <p className="text-xsmall text-bold">{ticket.card ? HippodromesMap[ticket.card.split("-")[1]]?.HIPODROM : ''}</p>
                                        </div>
                                        <div className="mobile-ticket-detail-row">
                                            <p className="text-xsmall">Koşu</p>
                                            <p className="text-xsmall text-bold">{ticket.race_id}</p>
                                        </div>
                                        <div className="mobile-ticket-detail-row">
                                            <p className="text-xsmall">Misli</p>
                                            <p className="text-xsmall text-bold">{ticket.multiply}</p>
                                        </div>
                                        <div className="mobile-ticket-detail-row">
                                            <p className="text-xsmall">Kupon Tutarı</p>
                                            <p className="text-xsmall text-bold">{prop.status === 2 ? `${ticket.refund !== ticket.winning && ticket.winning + '₺'}` : `${CurrencyFormat(Number(ticket.amount || 0))}₺`}</p>
                                        </div>
                                        <div className="d-flex justify-content-center align-items-center col-gap-8">
                                            {BulletinMapCardID[Number(ticket.card_id)]?.runsMap[ticket.race_id]?.status === 0 && ticket.status === 1 && ticket.bet_type !== 1881 &&
                                                <button onClick={() => {
                                                    cancelTjkCoupon(ticket);
                                                }}
                                                        className="button xs danger  mobile-ticket-cancel-btn me-1">
                                                    İptal Et
                                                </button>
                                            }
                                            <button className="button xs primary filled mobile-ticket-detail-btn "
                                                    onClick={() => {
                                                        showDetail(ticket);
                                                    }}>
                                                <p className="text-small  text-primary text-bold">Bahis Detayları</p>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="6" height="12"
                                                     viewBox="0 0 6 12" fill="none">
                                                    <path d="M1 11L5 6L1 1" stroke="#004742" strokeWidth="1.5"
                                                          strokeLinecap="round" strokeLinejoin="round"></path>
                                                </svg>
                                            </button>

                                        </div>
                                    </div>
                                </React.Fragment>
                            );
                        })}
                </div> : <div>
                    <table className={"ticket-table mt-2"}>
                        <thead>
                        <tr>
                            <th>Tarih ve Kupon No</th>
                            {prop.status !== 6 && <th>Durum</th>}
                            <th>Bahis Türü</th>
                            <th>Hipodrom</th>
                            <th>Koşu</th>
                            <th>Misli</th>
                            <th>
                                {prop.status === 2 ? 'Kazanç Tutarı' : 'Kupon Tutarı'}
                            </th>
                            <th style={{width: "55px"}}></th>
                            {(status === 6 || status === null) && <th style={{padding: '0'}}></th>}
                        </tr>
                        </thead>
                        <tbody>

                        {listTickets &&
                            listTickets.map((ticket: any, keyTicket: number) => {
                                // console.log(BulletinMapCardID[Number(ticket.card_id)]?.runsMap[ticket.race_id]?.status);
                                // console.log(BulletinMapCardID[Number(ticket.card_id)]);
                                return (
                                    <React.Fragment key={`tickets-${keyTicket}`}>
                                        <tr>
                                            <td>
                                                <div className="d-flex">
                                                    <p className="text-xsmall ticket-id">{ticket.status !== 6 && ticket.approve_code ? ticket.approve_code : (ticket.status === 6 ? ticket.id : "Oynanıyor")}</p>
                                                    <svg width="12" height="12" viewBox="0 0 12 12" fill="none"
                                                         xmlns="http://www.w3.org/2000/svg">
                                                        <rect x="4.23779" y="2.08345" width="5.67949" height="5.68011"
                                                              rx="1.25"
                                                              stroke=" #929191" strokeWidth="1.5"></rect>
                                                        <path
                                                            d="M7.84632 9.23151V9.23151C7.84632 10.0246 7.20339 10.6675 6.41029 10.6675H3.3335C2.22893 10.6675 1.3335 9.7721 1.3335 8.66753V5.59004C1.3335 4.79702 1.97637 4.15414 2.76939 4.15414V4.15414"
                                                            stroke=" #929191" strokeWidth="1.5"></path>
                                                    </svg>
                                                </div>
                                                <p className="text-xsmall">{dateTimeFormat(ticket.approve_date ?? ticket.create_date).replace(' ', ' , ')}</p>
                                            </td>
                                            {prop.status !== 6 && <td>
                                                <div className="d-flex align-items-center">
                                                    <p className="text-xsmall text-bold me-1"
                                                       style={{color: statusMap[ticket.status].class}}>{statusMap[ticket.status].label}</p>
                                                    <span
                                                        dangerouslySetInnerHTML={{__html: statusMap[ticket.status].icon}}></span>
                                                </div>
                                            </td>}
                                            <td className="ticket-bet-type">
                                                <p className="text-xsmall text-bold">{BettingTypesMap[Number(ticket.bet_type)]?.name}</p>
                                            </td>
                                            <td className="long-text">
                                                <p className="text-xsmall text-bold">{ticket.card ? HippodromesMap[ticket.card.split("-")[1]]?.HIPODROM : ''}</p>
                                            </td>
                                            <td>
                                                <p className="text-xsmall">{ticket.race_id}</p>
                                            </td>
                                            <td>
                                                <p className="text-xsmall text-bold">{ticket.multiply}</p>
                                            </td>
                                            <td>
                                                <p className="text-xsmall text-bold"
                                                   style={{color: "rgb(53, 53, 53)"}}>

                                                    {prop.status === 2 ? `${ticket.refund !== ticket.winning && ticket.winning + '₺'}` : `${CurrencyFormat(Number(ticket.amount || 0))}₺`}
                                                </p>
                                            </td>
                                            <td className="ticket-detail-button" onClick={() => {
                                                showDetail(ticket);
                                            }}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="6" height="12"
                                                     viewBox="0 0 6 12"
                                                     fill="none">
                                                    <path d="M1 11L5 6L1 1" stroke="#004742" strokeWidth="1.5"
                                                          strokeLinecap="round"
                                                          strokeLinejoin="round"></path>
                                                </svg>
                                            </td>
                                            {prop.status === 6 && <td style={{width: "147px"}}>
                                                <div className="ticket-play-or-delete">
                                                    <button onClick={() => {
                                                        playTicket(ticket);
                                                    }}
                                                            className="button xs tertiary outlined play-saved-ticket ">
                                                        <span
                                                            className="text-xsmall text-primary fw-medium d-flex justify-content-center align-items-center">Hemen Oyna</span>
                                                    </button>
                                                    <div onClick={() => {
                                                        deleteTjkCoupon(ticket);
                                                    }}
                                                         className="delete-saved-ticket d-flex justify-content-center align-items-center">
                                                        <svg width="15" height="18" viewBox="0 0 15 18" fill="none"
                                                             xmlns="http://www.w3.org/2000/svg">
                                                            <path fillRule="evenodd" clipRule="evenodd"
                                                                  d="M0.977084 2.86733H4.23876V1.09069C4.23876 0.532137 4.69546 0.0754395 5.25379 0.0754395H9.7462C10.3048 0.0754395 10.7615 0.532137 10.7615 1.09069V2.86733H14.0229C14.5604 2.86733 15 3.30694 15 3.84419C15 4.33639 14.6314 4.74627 14.1567 4.81195V15.5443C14.1567 16.1994 13.8891 16.7946 13.4581 17.2258C13.027 17.657 12.4318 17.9246 11.7767 17.9246H3.22351C2.56842 17.9246 1.97325 17.657 1.54207 17.2258C1.1109 16.7946 0.84327 16.1994 0.84327 15.5443V4.81195C0.368598 4.74627 0 4.33639 0 3.84419C0 3.30694 0.439832 2.86733 0.977084 2.86733ZM10.6006 6.26815C11.075 6.26815 11.4631 6.65627 11.4631 7.13095V14.5096C11.4631 14.984 11.075 15.3721 10.6006 15.3721C10.1261 15.3721 9.73799 14.984 9.73799 14.5096V7.13095C9.73799 6.65627 10.1261 6.26815 10.6006 6.26815ZM7.5 6.26815C7.97445 6.26815 8.36258 6.65627 8.36258 7.13095V14.5096C8.36258 14.984 7.97445 15.3721 7.5 15.3721C7.02555 15.3721 6.63742 14.984 6.63742 14.5096V7.13095C6.63742 6.65627 7.02555 6.26815 7.5 6.26815ZM4.39943 6.26815C4.87388 6.26815 5.26201 6.65627 5.26201 7.13095V14.5096C5.26201 14.984 4.87388 15.3721 4.39943 15.3721C3.92498 15.3721 3.53685 14.984 3.53685 14.5096V7.13095C3.53685 6.65627 3.92498 6.26815 4.39943 6.26815ZM13.0514 4.82128H1.94884V15.5443C1.94884 15.8943 2.09242 16.213 2.32365 16.4442C2.55489 16.6754 2.87355 16.819 3.22351 16.819H11.7767C12.1264 16.819 12.4451 16.6754 12.6763 16.4442C12.9076 16.213 13.0514 15.8943 13.0514 15.5443V4.82128ZM5.72358 2.86733H9.27664V1.47127H5.72358V2.86733Z"
                                                                  fill="#EC4950"></path>
                                                        </svg>
                                                    </div>
                                                </div>
                                            </td>}
                                            {BulletinMapCardID[Number(ticket.card_id)]?.runsMap[ticket.race_id]?.status === 0 && ticket.status === 1 &&
                                                <td className="">
                                                    <div className="d-flex justify-content-center">
                                                        <button onClick={() => {
                                                            playTicket(ticket);
                                                        }}
                                                                className="button xs tertiary outlined ticket-replay-btn ml-2 "
                                                                data-testid="custom-button"
                                                                data-tooltip-id="99936">
                                                            <svg width="17" height="16" viewBox="0 0 17 16" fill="none"
                                                                 xmlns="http://www.w3.org/2000/svg">
                                                                <path
                                                                    d="M13.5 2H10.5V0L7.5 3L10.5 6V4H13.5C13.7652 4 14.0196 4.10536 14.2071 4.29289C14.3946 4.48043 14.5 4.73478 14.5 5V11C14.5 11.2652 14.3946 11.5196 14.2071 11.7071C14.0196 11.8946 13.7652 12 13.5 12H11.5V14H13.5C14.2956 14 15.0587 13.6839 15.6213 13.1213C16.1839 12.5587 16.5 11.7956 16.5 11V5C16.5 4.20435 16.1839 3.44129 15.6213 2.87868C15.0587 2.31607 14.2956 2 13.5 2Z"
                                                                    fill="#004742"></path>
                                                                <path
                                                                    d="M3.5 14H6.5V16L9.5 13L6.5 10V12H3.5C3.23478 12 2.98043 11.8946 2.79289 11.7071C2.60536 11.5196 2.5 11.2652 2.5 11V5C2.5 4.73478 2.60536 4.48043 2.79289 4.29289C2.98043 4.10536 3.23478 4 3.5 4H5.5V2H3.5C2.70435 2 1.94129 2.31607 1.37868 2.87868C0.816071 3.44129 0.5 4.20435 0.5 5V11C0.5 11.7956 0.816071 12.5587 1.37868 13.1213C1.94129 13.6839 2.70435 14 3.5 14Z"
                                                                    fill="#004742"></path>
                                                            </svg>
                                                        </button>
                                                        {ticket.bet_type !== 1881 && <button onClick={() => {
                                                            cancelTjkCoupon(ticket);
                                                        }}
                                                                 className="button xs tertiary outlined cancel-coupon-btn "
                                                                 data-testid="custom-button"
                                                                 data-tooltip-id="61392767945439">
                                                            <svg width="16" height="16" viewBox="0 0 24 24"
                                                                 fill="#004742" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M18 6L6 18M6 6L18 18" stroke="#004742"
                                                                      strokeWidth="3" strokeLinecap="round"
                                                                      strokeLinejoin="round"></path>
                                                            </svg>
                                                        </button>}
                                                    </div>
                                                </td>
                                            }
                                        </tr>
                                    </React.Fragment>
                                );
                            })}

                        </tbody>
                    </table>
                    <div className="pagination-wrapper">
                        <ul role="navigation" aria-label="Pagination">
                            <li className="pagination-prev disable-page">
                                <a className=" " tabIndex={-1} role="button" aria-disabled="true"
                                   aria-label="Previous page">
                                    <div className="d-flex">
                                        <svg width="12" height="12" viewBox="0 0 12 12" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path d="M2.5 6H9.5M9.5 6L6 2.5M9.5 6L6 9.5" stroke="#353535"
                                                  strokeWidth="1.66" strokeLinecap="round"
                                                  strokeLinejoin="round"></path>
                                        </svg>
                                        <p className={"disable-page-button"}> Geri</p>
                                    </div>
                                </a>
                            </li>
                            <li className="page selected">
                                <a rel="canonical" role="button"
                                   className="selected-page"
                                   tabIndex={-1}
                                   aria-label="Page 1 is your current page"
                                   aria-current="page">1</a>
                            </li>
                            <li className="page-next disable-page">
                                <a className=" " tabIndex={-1} role="button" aria-disabled="true"
                                   aria-label="Next page"
                                   rel="next">
                                    <div className="d-flex">
                                        <p className={"disable-page-button"}> İleri</p>
                                        <svg width="12" height="12" viewBox="0 0 12 12" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path d="M2.5 6H9.5M9.5 6L6 2.5M9.5 6L6 9.5" stroke="#353535"
                                                  strokeWidth="1.66" strokeLinecap="round"
                                                  strokeLinejoin="round"></path>
                                        </svg>
                                    </div>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>}
            </div>
            <Modal
                isOpen={detailTicket !== null}
                onRequestClose={() => {
                    setDetailTicket(null);
                    setSelectedLeg(0);
                }}
                ariaHideApp={false}
                style={{
                    content: {
                        top: '50%',
                        left: '50%',
                        right: 'auto',
                        bottom: 'auto',
                        width: isMobile ? '98%' : '1140px',
                        maxHeight: '759px',
                        marginRight: '-50%',
                        maxWidth: '472px',
                        padding: '0',
                        transform: 'translate(-50%, -50%)',
                        background: 'var(--bs-white)',
                        borderRadius: 'var(--bs-border-radius-xl)',
                        border: 0
                    },
                    overlay: {
                        zIndex: 5,
                        backgroundColor: 'rgba(0, 0, 0, 0.25)',
                        backdropFilter: 'blur(10px)',
                    }
                }}
                contentLabel=""
            >
                {detailTicket &&
                    <div>
                        <div className="modal-header">
                            <h3 className="text-lg mb-0 text-white text-bolder text-center w-100">Kupon Detay</h3>
                            <svg onClick={() => {
                                setDetailTicket(null);
                                setSelectedLeg(0);
                            }} width="24" height="24" viewBox="0 0 24 24" fill="white"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path d="M18 6L6 18M6 6L18 18" stroke="white" strokeWidth="2" strokeLinecap="round"
                                      strokeLinejoin="round"></path>
                            </svg>
                        </div>
                        <div className="ticket-modal-body">
                            <div className="pt-4 ticket-detail-row flex-wrap">
                                <p className="text-xsmall text-bold">{BettingTypesMap[Number(detailTicket.bet_type)]?.name}</p>
                                <div className="d-flex justify-content-between flex-wrap align-items-center">
                                    <p className="text-xsmall text-bold me-1"
                                       style={{color: statusMap[detailTicket.status].class}}>{statusMap[detailTicket.status].label}</p>
                                    <span
                                        dangerouslySetInnerHTML={{__html: statusMap[detailTicket.status].icon}}></span>
                                </div>
                            </div>
                            <div className="d-flex pb-3 justify-content-between"
                                 style={{borderBottom: "1px solid gray"}}>
                                <p className="text-xsmall">Kupon
                                    no: {detailTicket.status !== 6 && detailTicket.approve_code ? detailTicket.approve_code : (detailTicket.status === 6 ? detailTicket.id : "Oynanıyor")}</p>
                                <p className="text-xsmall"> {dateTimeFormat(detailTicket.approve_date).replace(' ', ' , ')}</p>
                            </div>
                            <div className="ticket-detail-row">
                                <p className="text-xsmall ">Hipodrom</p>
                                <p className="text-xsmall text-bold">{detailTicket.card ? HippodromesMap[detailTicket.card.split("-")[1]]?.HIPODROM : ''}</p>
                            </div>
                            <div className="ticket-detail-row">
                                <p className="text-xsmall">Koşu</p>
                                <p className="text-xsmall text-bold">{detailTicket.race_id}</p>
                            </div>
                            <div className="ticket-detail-row">
                                <p className="text-xsmall">Misli</p>
                                <p className="text-xsmall text-bold">{detailTicket.multiply}</p>
                            </div>
                            <div className="ticket-detail-row">
                                <p className="text-xsmall">Kupon Tutarı</p>
                                <p className="text-xsmall text-bold">{CurrencyFormat(Number(detailTicket.amount || 0))}₺</p>
                            </div>
                            {detailTicket.status === 2 &&
                                <div className="ticket-detail-row">
                                    <p className="text-xsmall">Kazanç Tutarı</p>
                                    <p className="text-xsmall text-bold"> {detailTicket.refund !== detailTicket.winning && detailTicket.winning + '₺'}</p>
                                </div>}
                            <div className="mt-3 mb-3">
                                <div className="detail-legs">
                                    {detailTicket.legs.map((leg: any, key: number) => {
                                        return <button onClick={() => {
                                            setSelectedLeg(key);
                                        }} key={"ticket-" + key}
                                                       className={`button tertiary filled leg-button  ${selectedLeg === key ? 'selected' : ''}`}>
                                            <p className="text-small text-bolder">{BettingTypesMap[Number(detailTicket.bet_type)].columnLabel === 'Koşu' ? Number(detailTicket.race_id) + Number(key) : Number(key + 1)}</p>
                                            <p className="text-xxsmall"> {BettingTypesMap[Number(detailTicket.bet_type)].columnLabel}</p>
                                        </button>
                                    })}
                                </div>
                                {selectedLeg != null ?
                                    <ul className="selected-legs">
                                        {detailTicket.legs && detailTicket.legs[selectedLeg].map((item: any, horseKey: number) => {
                                            let horse = null;
                                            try {
                                                horse = RaceMapping[`${detailTicket.card_id}-${detailTicket.race_id}`].horses.find((e: any) => Number(e.no) === Number(item))
                                                if (BettingTypesMap[Number(detailTicket.bet_type)].columnLabel !== 'At') {
                                                    horse = RaceMapping[`${detailTicket.card_id}-${detailTicket.race_id + selectedLeg}`].horses.find((e: any) => Number(e.no) === Number(item))
                                                }
                                            } catch (e) {
                                                console.log(e);
                                                return <></>
                                            }

                                            let scoreString = ScoreMapping[`${detailTicket.card_id}-${detailTicket.bet_type}-${detailTicket.race_id + (BettingTypesMap[Number(detailTicket.bet_type)].columnLabel === 'At' ? 0 : selectedLeg)}`];

                                            if (tjkSingleLegBetTypes.includes(Number(detailTicket.bet_type)) || ([52, 55, 62].includes(Number(detailTicket.bet_type)) && detailTicket.legs.length === 1)) {
                                                scoreString = scoreString.toString().replaceAll('/', ',')
                                            }

                                            let colScore: any = '';
                                            if (BettingTypesMap[Number(detailTicket.bet_type)].columnLabel === 'At') {
                                                colScore = scoreString?.split(runSeparator)[selectedLeg]
                                            } else {
                                                colScore = scoreString
                                            }
                                            return <li key={"leg-" + horseKey}
                                                       className="d-flex justify-content-between">
                                                <p className="text-xxsmall d-flex"
                                                   style={{color: horse && scoreString && colScore?.split(horseSeparator)?.includes(horse.no) ? 'green' : 'var(--text-color)'}}> {horse ? <> {horse.no} - {horse.name} </> : <>{Number(item) === 32 ? (mobileView ? '(S)' : 'HAZIR BAHİS') : {item}}</>} </p>
                                                <div className="d-flex">
                                                    <div className="d-flex">
                                                        {horse?.AGF1 &&
                                                            <div style={{width: "87px", margin: "auto 12px auto auto"}}>
                                                                <div style={{position: "relative", minWidth: "80px"}}>
                                                                    <div
                                                                        style={{
                                                                            height: "16px",
                                                                            width: `${horse?.AGF1}%`,
                                                                            backgroundColor: "rgb(232, 230, 208)",
                                                                            borderRadius: "0px"
                                                                        }}></div>
                                                                    <div
                                                                        style={{
                                                                            position: "absolute",
                                                                            top: "0px",
                                                                            width: "87px",
                                                                            height: "100%",
                                                                            display: "flex",
                                                                            alignItems: "center",
                                                                            justifyContent: "center",
                                                                            borderRadius: "2px",
                                                                            border: "1px solid rgb(217, 217, 217)"
                                                                        }}>
                                                                        <p className="text-xxxsmall text-center">
                                                                    <span
                                                                        className="text-xxsmall text-bolder">AGF: </span>{horse?.AGF1}
                                                                            ({horse?.AGF_rank1})</p>
                                                                    </div>
                                                                </div>
                                                            </div>}
                                                        {horse?.AGF2 && <div style={{width: "87px", margin: "auto"}}>
                                                            <div style={{position: "relative", minWidth: "80px"}}>
                                                                <div
                                                                    style={{
                                                                        height: "16px",
                                                                        width: `${horse?.AGF2}%`,
                                                                        backgroundColor: "rgb(232, 230, 208)",
                                                                        borderRadius: "0px"
                                                                    }}></div>
                                                                <div
                                                                    style={{
                                                                        position: "absolute",
                                                                        top: "0px",
                                                                        width: "87px",
                                                                        height: "100%",
                                                                        display: "flex",
                                                                        alignItems: "center",
                                                                        justifyContent: "center",
                                                                        borderRadius: "2px",
                                                                        border: "1px solid rgb(217, 217, 217)"
                                                                    }}>
                                                                    <p className="text-xxxsmall text-center">
                                                                    <span
                                                                        className="text-xxsmall text-bolder">AGF: </span>{horse?.AGF2}
                                                                        ({horse?.AGF_rank2})</p>
                                                                </div>
                                                            </div>
                                                        </div>}
                                                    </div>
                                                    <p className="text-xxsmall fw-medium ms-2 me-1"
                                                       style={{
                                                           width: "10px",
                                                           color: horse && scoreString && colScore?.split(horseSeparator)?.includes(horse.no) ? 'green' : 'var(--text-color)'
                                                       }}>
                                                        {RaceMapping[`${detailTicket.card_id}-${detailTicket.race_id}`].is_finished ? <>
                                                            {!horse.result && horse.degree === null ? '(K)' : (horse.result ? `(${horse.result})` : '')}
                                                        </> : <>{horse.run_status === false ? '(K)' : ''}</>}
                                                    </p>
                                                </div>
                                            </li>
                                        })}
                                    </ul> : <></>
                                }
                            </div>
                        </div>
                    </div>
                }
            </Modal>

        </>

    );
}


//
// ------------
//
//
//     <div className="Coupons_notFoundCoupons__Mxg31">
//         <p className="text xs default normal left  none   lh-normal" data-testid="custom-text">
//             <svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 80 80" fill="none">
//                 <path
//                     d="M39.9993 53.3333V40M39.9993 26.6666H40.0327M73.3327 40C73.3327 58.4095 58.4088 73.3333 39.9993 73.3333C21.5899 73.3333 6.66602 58.4095 6.66602 40C6.66602 21.5905 21.5899 6.66663 39.9993 6.66663C58.4088 6.66663 73.3327 21.5905 73.3327 40Z"
//                     stroke="#929191" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"></path>
//             </svg>
//         </p>
//         <p className="text xl default bolder left mb-3 mt-3 none   lh-lg" data-testid="custom-text">Veri Bulunamadı</p>
//         <p className="text sm default normal left  none   lh-normal" data-testid="custom-text">Seçilen kriterlere uygun
//             kupon bulunamamıştır.</p></div>
//
//
//         .Coupons_notFoundCoupons__Mxg31 {
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     flex-direction: column;
//     min-height: 400px;
// }
